import React from "react"

function ProductColumns({ productColumnsColumn1, productColumnsColumn2, productColumnsColumn3, productColumnsTitle }){
return (
  <section className="flexible very-light-blue-background">

      <div className="columns content-block">
      <div className="eighty-spacer"></div>
      <h3 className="savings-title">
          {productColumnsTitle}    </h3>
      <div className="sixty-spacer"></div>
      <div className="one-flex-container">
              <div className="third-flex-container staggerFadeIn stag-visible">
                  <div className="wrapper">
                    <p>{productColumnsColumn1.productColumnsColumn1Label}</p>
                    <div className="htb-difference-title-holder">
                  {productColumnsColumn1.productColumnsColumn1Title}</div>
                    <div  className="htb-difference-text-holder" dangerouslySetInnerHTML={{ __html: productColumnsColumn1.productColumnsColumn1Content }}/>
                  </div>
              </div>
              <div className="third-flex-container staggerFadeIn stag-visible">
                <div className="wrapper">
                <p>{productColumnsColumn2.productColumnsColumn2Label}</p>
                  <div className="htb-difference-title-holder">{productColumnsColumn2.productColumnsColumn2Title}</div>
                  <div  className="htb-difference-text-holder" dangerouslySetInnerHTML={{ __html: productColumnsColumn2.productColumnsColumn2Content }}/>
              </div>
              </div>
              <div className="third-flex-container staggerFadeIn stag-visible">
              <div className="wrapper">
                <p>{productColumnsColumn3.productColumnsColumn3Label}</p>
                <div className="htb-difference-title-holder">{productColumnsColumn3.productColumnsColumn3Title}</div>
                <div  className="htb-difference-text-holder" dangerouslySetInnerHTML={{ __html: productColumnsColumn3.productColumnsColumn3Content }}/>
              </div>
            </div>
      </div>
      <div className="sixty-spacer"></div>
  </div>
  </section>
 )
  }


export default ProductColumns
